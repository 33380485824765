import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Layout from '../components/layout'

const ImpressumPage = ({ impressum }) => {
    const data = useStaticQuery(graphql`
    {
        impressum: strapiSitePage(strapiId: {eq: 3}) {
            subtitle
            content
            id
            title
          }
        
    }
    `

    )

    return (
<Layout>
<div className="px-4">
    <div className="w-full p-6 flex flex-col flex-grow flex-shrink">
        <div className="flex-1 bg-white dark:bg-gray-800 rounded-t rounded-b-none overflow-hidden">

          <p className="w-full text-4xl md:text-4xl font-medium px-6 uppercase mt-6">{data.impressum.title}</p>
          <div className="w-full font-light text-2xl text-joker px-6 italic dark:text-gray-400 dark:bg-gray-600 mt-4">{data.impressum.subtitle}</div>
          <p className="text-gray-800 font-sans text-base px-6 mb-5 mt-6 leading-relaxed dark:text-gray-200">
          <ReactMarkdown source={data.impressum.content}
        transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
      />
            
          </p>
     
        </div>
      </div>
      </div>
      </Layout>
 )
}

export default ImpressumPage